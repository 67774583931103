<template>
  <div class="Menu_manage">
    <div class="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <p class="search">
        <button
          class="btn2"
          @click="
            modal = 1;
            ruleForm.modalType = 1;
          "
          v-if="permissoin.includes('add')"
         
        >
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
        <el-table
          :data="tableData"
          style="width: 98%"
          border
          row-key="id"
          max-height="800px"
          ref="refTable"
          @row-click="expandChange"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="name" label="菜单名称" width="250">
          </el-table-column>
          <el-table-column prop="menuType" label="菜单类型">
            <template slot-scope="scope">
              <el-button
                plain
                type="primary"
                size="mini"
                v-show="scope.row.menuType == 0"
                >目录</el-button
>
              <el-button
                plain
                type="success"
                size="mini"
                v-show="scope.row.menuType == 1"
                >菜单</el-button
              >
              <el-button
                plain
                type="info"
                size="mini"
                v-show="scope.row.menuType == 2"
                >按钮</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="menuIcon" label="图标"> </el-table-column>
          <el-table-column prop="url" label="路径"> </el-table-column>
          <el-table-column prop="solt" label="排序"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div @click.stop>
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit"
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-delete"
                      v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="modal" v-if="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-show="ruleForm.modalType == 1">新增菜单</span>
          <span v-show="ruleForm.modalType == 2">修改菜单</span>
          <span
            @click="
              modal = 0;
              ruleForm = { value2: 1, menuType: 1 };
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="菜单名:" prop="name">
              <el-input v-model="ruleForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="菜单" prop="menuType">
              <el-radio-group v-model="ruleForm.menuType" size="mini">
                <el-radio :label="0">目录</el-radio>
                <el-radio :label="1">菜单</el-radio>
                <el-radio :label="2">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <p v-show="ruleForm.menuType==1">请选择目录</p>
                <p v-show="ruleForm.menuType==2">请选择菜单</p> -->
            <!-- <el-tree v-show="ruleForm.menuType!='0'" :props="props" :data="treedata" @node-click="nodeClick"></el-tree> -->

            <!-- <el-cascader
                  placeholder="试试搜索:菜单"
                  :props="props"
                  @change="nodeClick"
                  :options="treedata"
                  filterable>
                  </el-cascader> -->
            <el-form-item
              label="父级:"
              prop="id"
              v-show="ruleForm.menuType != '0'"
            >
              <!-- <el-input v-model="ruleForm.pidname" size='mini'></el-input> -->
              <el-cascader
                ref="cas"
                size="mini"
                v-model="ruleForm.id"
                placeholder="试试搜索:菜单"
                :props="props"
                @change="nodeClick"
                :options="treedata"
                filterable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item
              label="权限标识:"
              prop="permission"
              v-if="ruleForm.menuType == '2'"
              :rules="[
                { required: true, message: '请输入权限标识', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="ruleForm.permission"
                size="mini"
                placeholder="按照格式输入权限标识"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="路径:"
              v-show="ruleForm.menuType != 2"
              prop="url"
            >
              <el-input v-model="ruleForm.url" size="mini"></el-input>
            </el-form-item>
            <el-form-item
              label="排序:"
              prop="solt"
              v-show="ruleForm.menuType != 2"
            >
              <el-input v-model="ruleForm.solt" size="mini"></el-input>
            </el-form-item>
            <el-form-item
              label="图标:"
              prop="menuIcon"
              v-if="ruleForm.menuType == 0"
              :rules="[
                { required: true, message: '请选择图标', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="ruleForm.menuIcon"
                @focus="modal = 2"
                size="mini"
                placeholder="点击选择图标"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="input_item" v-show="ruleForm.menuType != 2">
            <span>是否可见:</span>
            <el-switch
              style="display: block"
              v-model="ruleForm.value2"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="开启"
              :active-value="1"
              :inactive-value="2"
              inactive-text="关闭"
            >
            </el-switch>
          </div>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              modal = 0;
              ruleForm = { value2: 1, menuType: 1 };
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="modal == 2">
      <div class="inner">
        <p class="title">
          <span>选择图标</span>
          <span @click="modal = 0" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center1">
          <span
            v-for="item in iconArr"
            :key="item"
            @click="
              ruleForm.menuIcon = item;
              modal = 1;
            "
          >
            <i :class="item"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMenuAll,
  saveMenu,
  queryMenuById,
  menuEdit,
  menuDel,
} from "@/api/apis.js";
export default {
  data() {
    return {
      option1: [],
      form: {},
      info: 1,
      currentPage2: 1,
      hide: 1,
      dialogVisible: false,
      modal: 0,
      total: 0,
      permissoin: [],
      searchForm: {},
      ruleForm: {
        pwd: 123456,
        date1: "",
        date2: "",
        value2: 1,
        menuType: 1,
        pidname: "",
        modalType: 0,
      },
      iconArr: [
        "el-icon-setting",
        "el-icon-user",
        "el-icon-star-off",
        "el-icon-goods",
        "el-icon-help",
        "el-icon-picture-outline",
        "el-icon-picture-outline-round",
        "el-icon-camera",
        "el-icon-video-camera",
        "el-icon-c-scale-to-original",
        "el-icon-date",
        "el-icon-folder-opened",
        "el-icon-tickets",
        "el-icon-document-copy",
        "el-icon-printer",
        "el-icon-monitor",
        "el-icon-umbrella",
        "el-icon-brush",
        "el-icon-data-line",
        "el-icon-notebook-2",
        "el-icon-office-building",
        "el-icon-school",
        "el-icon-shopping-cart-1",
        "el-icon-odometer",
        "el-icon-mic",
        "el-icon-location-information",
        "el-icon-watch",
        "el-icon-truck",
        "el-icon-sunny",
        "el-icon-user-solid",
        "el-icon-phone",
        "el-icon-s-goods",
        "el-icon-camera-solid",
        "el-icon-video-camera-solid",
        "el-icon-s-platform",
        "el-icon-s-order",
        "el-icon-s-promotion",
        "el-icon-s-management",
        "el-icon-s-shop",
        "el-icon-s-marketing",
        "el-icon-s-finance",
        "el-icon-s-claim",
        "el-icon-s-custom",
        "el-icon-s-opportunity",
        "el-icon-s-data",
        "el-icon-s-check",
        "el-icon-s-grid",
        "el-icon-share",
        "el-icon-menu",
        "el-icon-s-flag",
      ],
      options: [
      ],
      i: 0,
      treedata: [],
      editForm: {},
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      props1: {
        label: "name",
        children: "children",
      },
      tableData: [],
      rules: {
        name: [{ required: true, message: "请输入菜单名", trigger: "blur" }],
        id: [{ required: true, message: "请选择父级", trigger: "blur" }],
        solt: [{ required: true, message: "请输入排序", trigger: "blur" }],
        //  menuIcon: [
        //   { required: true, message: '请选择图标', trigger: 'blur' },
        // ],
        url: [{ required: true, message: "请输入菜单路径", trigger: "blur" }],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    getData() {
      getMenuAll().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.treedata = res.data;
        } else {
          this.tableData = [];
        }
      });
    },
    nodeClick() {
      let a = this.$refs.cas.getCheckedNodes()[0].data;
      this.ruleForm.pidname = a.name;
      this.ruleForm.pid = a.id;
      this.ruleForm.pids = a.pids + "," + a.id;
      this.$forceUpdate();
    },
    handleCommand(d) {
      if (d.num == 2) {
        let data = {
          id: d.data.id,
        };
        queryMenuById(data).then((res) => {
          if (res.code == 200) {
            this.modal = 1;
            this.ruleForm = res.data;
            this.ruleForm.modalType = 2;
          }
        });
      } else if (d.num == 3) {
        this.ruleForm.id = d.data.id;
        this.dialogVisible = true;
      }
    },
    deletePro() {
      let data = { id: this.ruleForm.id };
      menuDel(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    expandChange(row) {
      this.$refs.refTable.toggleRowExpansion(row);
    },
    onsubmit() {
      let data = {};
      if (this.ruleForm.menuType == 0) {
        data = {
          name: this.ruleForm.name,
          menuType: this.ruleForm.menuType,
          solt: this.ruleForm.solt,
           url: this.ruleForm.url,
          menuIcon: this.ruleForm.menuIcon,
          isShow: this.ruleForm.value2,
          // id: this.ruleForm.id?this.ruleForm.id[this.ruleForm.id.length-1]:'',
          id: this.ruleForm.id instanceof Array?this.ruleForm.id[this.ruleForm.id.length-1]:this.ruleForm.id,
          // id: this.ruleForm.id,
          pid: 0,
          pids: 0,
        };
      } else if (this.ruleForm.menuType == 1) {
        data = {
          name: this.ruleForm.name,
          menuType: this.ruleForm.menuType,
          solt: this.ruleForm.solt,
          pid: this.ruleForm.pid||0,
          pids: this.ruleForm.pids||0,
          url: this.ruleForm.url,
          isShow: this.ruleForm.value2,
          // id: this.ruleForm.id?this.ruleForm.id[this.ruleForm.id.length-1]:'',
          id: this.ruleForm.id instanceof Array?this.ruleForm.id[this.ruleForm.id.length-1]:this.ruleForm.id,
          // id: this.ruleForm.id,

        };
      } else if (this.ruleForm.menuType == 2) {
        data = {
          name: this.ruleForm.name,
          menuType: this.ruleForm.menuType,
          pids: this.ruleForm.pids||0,
          pid: this.ruleForm.pid||0,
          // id: this.ruleForm.id?this.ruleForm.id[this.ruleForm.id.length-1]:'',
          id: this.ruleForm.id instanceof Array?this.ruleForm.id[this.ruleForm.id.length-1]:this.ruleForm.id,
          permission: this.ruleForm.permission,
        };
      }
      if (this.ruleForm.modalType == 1) {
            saveMenu(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.modal = 0;
                this.ruleForm = {
                  pwd: 123456,
                  date1: "",
                  date2: "",
                  value2: "1",
                  menuType: 1,
                  pidname: "",
                };
                this.getData();
              } else {
                this.$message.error(res.data.msg);
              }
        });
      } else if (this.ruleForm.modalType == 2) {
        menuEdit(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.modal = 0;
            this.ruleForm = {
              pwd: 123456,
              date1: "",
              date2: "",
              value2: "1",
              menuType: 1,
              pidname: "",
            };
            this.getData();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
  mounted() {

    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    this.getData();
  },
};
</script>

<style lang="less" >
.Menu_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .el-button + .el-button {
    margin-left: 0px;
  }
  .index_main {
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 268px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      // width: 456px;
      // height: 316px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        padding: 0 30px;
        padding-top: 10px;
        min-height: 292px;
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            width: 60px;
          }
        }
        .el-form {
          .el-form-item__label {
            font-size: 12px;
            color: #222328;
          }
          .el-form-item {
            margin-bottom: 10px;
          }
          .el-input {
            width: 270px;
          }
          .el-form-item__error {
            padding-top: 0px;
          }
          // .el-form-item__content{
          //   margin-left: 0px !important;
          // }
          .two_item {
            display: flex;
            .ti_label {
              display: inline-block;
              width: 80px;
            }
            .ti_label1 {
              display: inline-block;
              width: 55px;
              margin-left: 14px;
              margin-right: 2px;
            }
            .el-input {
              width: 80px;
            }
            .little {
              font-size: 8px;
            }
          }
        }
        .input_item {
          margin-bottom: 24px;
          display: flex;
          & > span:nth-child(1) {
            display: inline-block;
            width: 80px;
            margin-right: 8px;
          }
          .el-input {
            width: 270px;
          }
        }
        .mc_right {
          width: 400px;
          .mcr_title {
            margin-top: 18px;
            margin-bottom: 22px;
            i {
              font-size: 14px;
              color: #f37d00;
            }
          }
          .input_item {
            margin-bottom: 24px;

            & > span:nth-child(1) {
              color: #f56c6c;
              margin-right: 2px;
            }
            & > span:nth-child(2) {
              width: 60px;
              display: inline-block;
              margin-right: 8px;
            }
            .el-input {
              width: 270px;
            }
          }
          .jwd {
            display: flex;
            justify-content: space-between;
            font-size: 8px;
            line-height: 18px;
            margin-top: -24px;
            width: 350px;
            box-sizing: border-box;
            padding: 0 6px;
            & > span:nth-child(2) {
              color: #0090ff;
            }
          }
          .map {
            // background-color: rgb(116, 35, 35);
            width: 360px;
            height: 310px;
          }
        }
      }
      .modal_center1 {
        width: 456px;
        padding: 10px 30px;
        span {
          width: 30px;
          height: 30px;
          display: inline-block;
          line-height: 30px;
          text-align: center;
          i {
            font-size: 20px;
          }
        }
        span:hover {
          background-color: #84c3f3;
          cursor: pointer;
        }
      }
      .bottom {
        height: 54px;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>